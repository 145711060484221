/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React from 'react'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'

// Components
import SEO from 'components/SEO'
import Layout from 'components/shared/Layout'
import Hero from 'components/shared/Hero'

// Third Party
import styled, { css } from 'styled-components'
import parse from 'html-react-parser'

const BlueBox = styled.div`
  background: ${props => props.theme.color.gradient_background};
  background: ${props => props.theme.color.gradient};
  position: relative;

  height: 460px;
  width: 460px;

  ${props => props.right && css`
    top: -40px;
  `}

  h2 {
    position: relative;

    left: 20px;
    top: 15px;
  }

  @media screen and (max-width: 575px) {
    height: 300px;
    width: 300px;

    ${props => props.right && css`
      top: -40px;
    `}
  }

  @media screen and (max-width: 382px) {
    height: 300px;
    width: 300px;
    left: -10px;

    ${props => props.right && css`
      top: -40px;
    `}

    h2 {
      font-size: ${props => props.theme.font.size.l};
    }
  }

  @media screen and (max-width: 340px) {
    height: 250px;
    width: 250px;
  }
`

const StyledImg = styled(Img)`
  position: relative;

  top: 70px;
  left: 20px;

  @media screen and (max-width: 991px) {
    top: 20px;
    left: 20px;
  }
`

const StyledBackgroundImg = styled(Img)`
  position: absolute !important;
  height: 120%;
  width: 1000px;
  z-index: -1;

  ${props => props.left && css`
    left: -300px;
  `}

  ${props => props.right && css`
    right: -300px;
  `}


  @media screen and (max-width: 991px) {
    height: 250%;

    ${props => props.left && css`
      top: -30px;
    `}

    ${props => props.right && css`
      top: -30px;
    `}
  }

  @media screen and (max-width: 575px) {
    height: 200%;
  }

  @media screen and (max-width: 382px) {
    height: 170%;
  }
`

const Content = styled.div`
  h2 {
    font-size: ${props => props.theme.font.size.xxl};
  }

  p {
    line-height: 30px;
    font-weight: ${props => props.theme.font.weight.s};
    color: ${props => props.theme.color.text.secondary};
  }
`

const Service = styled.div`
  padding-bottom: 200px;

  @media screen and (max-width: 991px) {
    padding-bottom: 100px;
  }
`

const Id = styled.div`
  position: relative;
  top: -150px;
`

const ServicesTemplate = ({
  data: {
    page: {
      path,
      acf: {
        banner
      },
      yoastMeta
    },
    services: {
      edges: services
    }
  },
  location
}) => {

  setTimeout(() => {
    if (typeof document !== "undefined") {
      if (location.hash !== "") {
        document.getElementById(location.hash).scrollIntoView({behavior: "smooth"});
      }
    }
  }, 300)

  return (
    <Layout>
      <SEO yoast={{ meta: yoastMeta }} pathname={path} article />
      <Hero className="mb-lg-5" content={banner} />

      <div className="container py-5">
        {services.map((service, index) => (
          <Service className="position-relative pt-lg-5 pt-3 mb-lg-5" key={service.node.wordpress_id}>
            <Id id={`#${service.node.acf.content.small_name}`} />
            {index % 2 === 0 ? (
              <div className="row">
                <div className="col-lg-6 px-lg-5 d-flex justify-content-center align-items-center">
                  <Content className="px-lg-4">
                    <h2 className="pb-lg-3 color-text-contrast font-weight-xl">{service.node.acf.content.full_name}</h2>
                    {parse(service.node.acf.content.text)}
                  </Content>
                  <StyledBackgroundImg left fluid={service.node.acf.big_image.localFile.childImageSharp.fluid} alt="" />
                </div>
                <div className="col-lg-6 pt-3 pt-lg-5 d-flex justify-content-center justify-content-lg-start">
                  <BlueBox left>
                    <StyledImg fluid={service.node.acf.small_image.localFile.childImageSharp.fluid} alt="" />
                  </BlueBox>
                </div>
              </div>
            ) : (
              <div className="row flex-wrap-reverse">
                <div className="col-lg-6 pt-3 pt-lg-5 d-flex justify-content-center justify-content-lg-start">
                  <BlueBox left>
                    <StyledImg fluid={service.node.acf.small_image.localFile.childImageSharp.fluid} alt="" />
                  </BlueBox>
                </div>
                <div className="col-lg-6 px-lg-5 d-flex justify-content-center align-items-center">
                  <Content className="px-lg-4">
                    <h2 className="pb-3 color-text-contrast font-weight-xl">{service.node.acf.content.full_name}</h2>
                    {parse(service.node.acf.content.text)}
                  </Content>
                  <StyledBackgroundImg right fluid={service.node.acf.big_image.localFile.childImageSharp.fluid} alt="" />
                </div>
              </div>
            )}
      
          </Service>
        ))}
      </div>
    </Layout>
  )
}

export default ServicesTemplate

export const pageQuery = graphql`
  query($wordpress_id: Int) {
    page: wordpressPage(wordpress_id: { eq: $wordpress_id }) {
      ...ServicesFrag
    }

    services: allWordpressWpDienst(sort: {fields: date, order: ASC}) {
      edges {
        node {
          ...ServiceFrag
        }
      }
    }
  }
`